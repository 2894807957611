<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.products.tabs.general.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.sub_type === 'Labeled'"
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.cat_sub_cat_types.label')"
                    label-for="class"
                    :state="errors && errors.class ? false : null"
                  >
                    <v-select
                      id="class"
                      v-model="item.class"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="catSubCatOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="class"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.class">
                      {{ errors.class[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-else
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.class.label')"
                    label-for="class"
                  >
                    <b-form-input
                      :id="`class`"
                      v-model="item.class"
                      :state="errors && errors.class ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.class">
                      {{ errors.class[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionTypes"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                      @input="changeType"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.subType.label')"
                    label-for="sub_type"
                    :state="errors && errors.sub_type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.sub_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionSubTypes[item.type]"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="sub_type"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.sub_type">
                      {{ errors.sub_type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.sub_type === 'Labeled'"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.stickers.label')"
                    label-for="sticker_id"
                    :state="errors && errors.sticker_id ? false : null"
                  >
                    <v-select
                      id="sticker_id"
                      v-model="item.sticker_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stickersOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="sticker_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.sticker_id">
                      {{ errors.sticker_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.sub_type === 'Categoryabled'"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.categories.label')"
                    label-for="category_id"
                    :state="errors && errors.category_id ? false : null"
                  >
                    <v-select
                      id="category_id"
                      v-model="item.category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoryOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="category_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.category_id">
                      {{ errors.category_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="false && item.type === 'Posts'"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.categories.label')"
                    label-for="category_id"
                    :state="errors && errors.category_id ? false : null"
                  >
                    <v-select
                      id="category_id"
                      v-model="item.category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoryPostsOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="category_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.category_id">
                      {{ errors.category_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.type === 'Products'"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.child.label')"
                    label-for="extension_id"
                    :state="errors && errors.extension_id ? false : null"
                  >
                    <v-select
                      id="extension_id"
                      v-model="item.extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      :clearable="true"
                      input-id="extension_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.extension_id">
                      {{ errors.extension_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        :label="$t('form.link.label')"
                        :label-for="'link.' + language.code"
                      >
                        <b-form-input
                          :id="'link.' + language.code"
                          v-model="item.link[language.code]"
                          :state="errors && errors['link.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['link.' + language.code]">
                          {{ errors['link.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        :label="$t('form.href.label')"
                        :label-for="'href.' + language.code"
                      >
                        <b-form-input
                          :id="'href.' + language.code"
                          v-model="item.href[language.code]"
                          :state="errors && errors['href.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['href.' + language.code]">
                          {{ errors['href.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="item.type === 'Html' || item.type === 'Seo' || item.type === 'Forms' || item.type === 'Contacts'"
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        :label="item.type === 'Html' || item.type === 'Contacts' ? 'HTML' : $t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <quill-editor
                          v-if="item.type === 'Seo'"
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-textarea
                          v-else-if="item.type === 'Html' || item.type === 'Contacts'"
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :state="errors && errors['description.' + language.code] ? false : null"
                          style="height: 500px;"
                        />
                        <b-form-input
                          v-else
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab
              v-if="item.type === 'Banners'"
              :title="$t('admin.extensions.tabs.banners.label')"
            >
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(banner, index) in item.banners"
                      :id="'banner' + index + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group :label="$t('form.background.label')">
                          <!-- media -->
                          <b-media no-body>
                            <b-media-aside>
                              <b-link>
                                <b-img
                                  ref="previewEl"
                                  rounded
                                  :src="$options.filters.mediaUrl(banner, 'background', '150x150', language.code)"
                                  height="80"
                                />
                              </b-link>
                              <!--/ avatar -->

                              <!-- reset -->
                              <b-button
                                v-if="banner.background[language.code]"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                size="sm"
                                class="mb-75 mr-75"
                                @click="banner.background[language.code] = null"
                              >
                                {{ $t('general.reset') }}
                              </b-button>
                              <!--/ reset -->
                            </b-media-aside>

                            <b-media-body class="mt-75 ml-75">
                              <InitFileManager
                                field="background"
                                type="extension_background"
                                :multiple="false"
                                @fm-selected="selectedBackground($event, index, language.code)"
                              />
                            </b-media-body>
                          </b-media>
                          <!--/ media -->
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          v-if="item.sub_type === 'BrandSlider'"
                          :label="$t('form.background.label') + ' - 2'"
                        >
                          <!-- media -->
                          <b-media no-body>
                            <b-media-aside>
                              <b-link>
                                <b-img
                                  ref="previewEl"
                                  rounded
                                  :src="$options.filters.mediaUrl(banner, 'background_mobile', '150x150', language.code)"
                                  height="80"
                                />
                              </b-link>
                              <!--/ avatar -->

                              <!-- reset -->
                              <b-button
                                v-if="banner.background_mobile[language.code]"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                size="sm"
                                class="mb-75 mr-75"
                                @click="banner.background_mobile[language.code] = null"
                              >
                                {{ $t('general.reset') }}
                              </b-button>
                              <!--/ reset -->
                            </b-media-aside>

                            <b-media-body class="mt-75 ml-75">
                              <InitFileManager
                                field="background_mobile"
                                type="extension_background_mobile"
                                :multiple="false"
                                @fm-selected="selectedBackgroundMobile($event, index, language.code)"
                              />
                            </b-media-body>
                          </b-media>
                          <!--/ media -->
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.position.label')"
                          :label-for="'banners.' + index + '.position'"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.position'"
                            v-model="banner.position"
                            :state="errors && errors['banners.' + index + '.position'] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.position']">
                            {{ errors['banners.' + index + '.' + '.position'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="8"
                      >
                        <b-form-group
                          :label="$t('form.href.label')"
                          :label-for="'banners.' + index + '.href'"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.href'"
                            v-model="banner.href"
                            :state="errors && errors['banners.' + index + '.href'] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.href']">
                            {{ errors['banners.' + index + '.' + '.href'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.blank.label')"
                          :label-for="'banners.' + index + '.blank'"
                        >
                          <b-form-checkbox
                            :id="'banners.' + index + '.blank'"
                            v-model="banner.blank"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.blank']">
                            {{ errors['banners.' + index + '.' + '.blank'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'banners.' + index + '.title.' + language.code"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.title.' + language.code"
                            v-model="banner.title[language.code]"
                            :state="errors && errors['banners.' + index + '.title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.title.' + language.code]">
                            {{ errors['banners.' + index + '.title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="8"
                      >
                        <b-form-group
                          :label="$t('form.description.label')"
                          :label-for="'banners.' + index + '.description.' + language.code"
                        >
                          <b-form-input
                            :id="'banners.' + index + '.description.' + language.code"
                            v-model="banner.description[language.code]"
                            :state="errors && errors['banners.' + index + '.description.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['banners.' + index + '.description.' + language.code]">
                            {{ errors['banners.' + index + '.description.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="12"
                        md="12"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeBanner(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addBanner"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.banners"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.banners[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.type === 'Faq'"
              :title="$t('admin.extensions.tabs.faq.label')"
            >
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(faq, index) in item.faq"
                      :id="'faq' + index + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.question.label')"
                          :label-for="'faq.' + index + '.title.' + language.code"
                        >
                          <b-form-input
                            :id="'faq.' + index + '.title.' + language.code"
                            v-model="faq.title[language.code]"
                            :state="errors && errors['faq.' + index + '.title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['faq.' + index + '.title.' + language.code]">
                            {{ errors['faq.' + index + '.title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.answer.label')"
                          :label-for="'description.' + language.code"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        >
                          <quill-editor
                            :id="'description.' + language.code"
                            v-model="faq.description[language.code]"
                            :options="editorOptions"
                            :state="errors && errors['description.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                            {{ errors['description.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeFaq(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addFaq"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.faq"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.faq[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.type === 'Tabs'"
              :title="$t('admin.extensions.tabs.tabs.label')"
            >
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(tab, index) in item.tabs"
                      :id="'tabs' + index + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-form-group :label="$t('form.icon.label')">
                          <!-- media -->
                          <b-media no-body>
                            <b-media-aside>
                              <b-link>
                                <b-img
                                  ref="previewEl"
                                  rounded
                                  :src="$options.filters.mediaUrl(tab, 'icon', '150x150')"
                                  height="80"
                                />
                              </b-link>
                              <!--/ avatar -->

                              <!-- reset -->
                              <b-button
                                v-if="tab.icon"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                size="sm"
                                class="mb-75 mr-75"
                                @click="tab.icon = null"
                              >
                                {{ $t('general.reset') }}
                              </b-button>
                              <!--/ reset -->
                            </b-media-aside>

                            <b-media-body class="mt-75 ml-75">
                              <InitFileManager
                                field="icon"
                                type="extension_icon"
                                :multiple="false"
                                @fm-selected="selectedIcon($event, index)"
                              />
                            </b-media-body>
                          </b-media>
                          <!--/ media -->
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="10"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'tabs.' + index + '.title.' + language.code"
                        >
                          <b-form-input
                            :id="'tabs.' + index + '.title.' + language.code"
                            v-model="tab.title[language.code]"
                            :state="errors && errors['tabs.' + index + '.title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['tabs.' + index + '.title.' + language.code]">
                            {{ errors['tabs.' + index + '.title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.description.label')"
                          :label-for="'description.' + language.code"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        >
                          <quill-editor
                            :id="'description.' + language.code"
                            v-model="tab.description[language.code]"
                            :options="editorOptions"
                            :state="errors && errors['tabs.' + index + '.description.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['tabs.' + index + '.description.' + language.code]">
                            {{ errors['tabs.' + index + '.description.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeTab(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addTab"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.tabs"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.tabs[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.type === 'Products' && item.sub_type === 'Default'"
              :title="$t('admin.extensions.tabs.products.label')"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(product, index) in item.products"
                  :id="'product' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('form.products.label')"
                      :label-for="'products.' + index + '.id'"
                      :state="errors && errors['products.' + index + '.id'] ? false : null"
                    >
                      <v-select
                        :id="'products.' + index + '.id'"
                        v-model="product.id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        :reduce="val => val.value"
                        :input-id="'products.' + index + '.id'"
                        :searchable="true"
                        @search="searchProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['products.' + index + '.id']">
                        {{ errors['products.' + index + '.id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeProduct(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addProduct"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.products"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.products[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.type === 'Posts'"
              :title="$t('admin.extensions.tabs.post.label')"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(post, index) in item.posts"
                  :id="'post' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('form.posts.label')"
                      :label-for="'posts.' + index + '.id'"
                      :state="errors && errors['posts.' + index + '.id'] ? false : null"
                    >
                      <v-select
                        :id="'posts.' + index + '.id'"
                        v-model="post.id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="postOptions"
                        :reduce="val => val.value"
                        :input-id="'posts.' + index + '.id'"
                        :searchable="true"
                        @search="searchPost"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['posts.' + index + '.id']">
                        {{ errors['posts.' + index + '.id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removePost(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPost"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.posts"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.posts[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'extensions-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import CategoryMixin from '@/mixins/CategoryMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [CategoryMixin, GlobalMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      stickersOptions: [],
      categoryOptions: [],
      categoryPostsOptions: [],
      productOptions: [],
      postOptions: [],
      extensionOptions: [],
      catSubCatOptions: [
        { label: this.$t('general.cat_sub_cat_types.subcategory'), value: 'subcategory' },
        { label: this.$t('general.cat_sub_cat_types.category'), value: 'category' },
        { label: this.$t('general.cat_sub_cat_types.no'), value: 'no' },
      ],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    this.item = await this.transformData()

    if (!Object.keys(this.stickersOptions).length) {
      await this.$http.get('/api/stickers', { params: { status: 'enabled', per_page: 100 } })
        .then(response => {
          this.stickersOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    }
    if (!Object.keys(this.extensionOptions).length) {
      await this.$http.get('/api/admin/extensions', { params: { status: 'enabled', per_page: 999 } })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    }
    if (!Object.keys(this.categoryOptions).length) {
      await this.$http.get('/api/admin/fullcategories', {
        params: {
          type: 'product',
          status: 'enabled',
        },
      })
        .then(response => {
          this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
        })
    }
    if (!Object.keys(this.categoryPostsOptions).length) {
      await this.$http.get('/api/admin/fullcategories', {
        params: {
          type: 'post',
          status: 'enabled',
        },
      })
        .then(response => {
          this.categoryPostsOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
        })
    }
  },
  methods: {
    selectedIcon(data, index) {
      this.item.tabs[index].icon = data
    },
    selectedBackground(data, index, language) {
      this.item.banners[index].background[language] = data
    },
    selectedBackgroundMobile(data, index, language) {
      this.item.banners[index].background_mobile[language] = data
    },
    changeType() {
      // eslint-disable-next-line prefer-destructuring
      this.item.sub_type = this.extensionSubTypes[this.item.type][0].value
      this.item.banners = []
      this.item.products = []
      this.item.posts = []
      this.item.faq = []
      this.item.tabs = []
    },
    async searchProduct(query) {
      await this.$http.get('/api/admin/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async searchPost(query) {
      await this.$http.get('/api/admin/posts', {
        params: {
          query,
        },
      })
        .then(response => {
          this.postOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        type: this.item.type,
        sub_type: this.item.sub_type,
        class: this.item.class,
        status: this.item.status,
        link: this.item.link,
        href: this.item.href,
        banners: this.item.banners,
        faq: this.item.faq,
        tabs: this.item.tabs,
        products: this.item.products,
        posts: this.item.posts,
        category_id: this.item.category_id,
        sticker_id: this.item.sticker_id,
        extension_id: this.item.extension_id,
      }

      this.$http.post('/api/admin/extensions', data)
        .then(() => {
          router.replace({ name: 'extensions-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addBanner() {
      const data = {
        title: {},
        description: {},
        href: null,
        product1: null,
        product2: null,
        product3: null,
        position: 0,
        blank: false,
        background: {},
        background_mobile: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
        data.background[language.code] = null
        data.background_mobile[language.code] = null
      })

      this.item.banners.push(data)
    },
    removeBanner(index) {
      this.item.banners.splice(index, 1)
    },
    addFaq() {
      const data = {
        title: {},
        description: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
      })

      this.item.faq.push(data)
    },
    removeFaq(index) {
      this.item.faq.splice(index, 1)
    },
    addTab() {
      const data = {
        icon: null,
        title: {},
        description: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
      })

      this.item.tabs.push(data)
    },
    removeTab(index) {
      this.item.tabs.splice(index, 1)
    },
    addProduct() {
      this.item.products.push({
        id: null,
      })
    },
    removeProduct(index) {
      this.item.products.splice(index, 1)
    },
    addPost() {
      this.item.posts.push({
        id: null,
      })
    },
    removePost(index) {
      this.item.posts.splice(index, 1)
    },
    transformData() {
      const data = {
        status: 'enabled',
        type: 'Seo',
        sub_type: 'Default',
        class: '',
        banners: [],
        faq: [],
        tabs: [],
        products: [],
        posts: [],
        title: {},
        description: {},
        link: {},
        href: {},
        category_id: null,
        sticker_id: null,
        extension_id: null,
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
        data.link[language.code] = null
        data.href[language.code] = null
      })

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
